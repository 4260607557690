import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box'; 

const HeroDescription = () => (
  <Box sx={{ textAlign: 'center', width: '100%' }}> 
    <Typography
      variant="h4" 
      sx={{
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        lineHeight: '1.5', 
        letterSpacing: '0.1rem', 
        margin: '1rem 0' 
      }}
    >
      Form a Team Quickly and Easily
    </Typography>
    <Typography
      variant="subtitle1"
      sx={{
        fontWeight: 'bold',
        mt: 2, 
      }}
    >
      Welcome to the global team-building platform to <strong>create</strong> teams, <strong> join</strong> teams,
      <strong> find</strong> teammates, and build a <strong>reputation</strong>.
    </Typography>
  </Box>
);

export default HeroDescription;
