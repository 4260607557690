import React from "react";

const HeroTitle = () => (
  <>
    <h1 className="landing--title">Start your Dream Team</h1>
    <h1 className="landing--title">Find Great Teammates!</h1>
  </>
);

export default HeroTitle;
