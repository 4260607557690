import React from "react";
import NextRouter from "next/router";
import { GlobalContext } from "../../context/GlobalContext";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import HeroDescription from "./HeroDescription";
import HeroTitle from "./HeroTitle";
import clsx from "clsx";


function HeroReusableV1({ loggedIn, versionNumber, headerWithDesc }) {
  const appState = React.useContext(GlobalContext);

  const { toggleModal, setModal } = appState;

  const openModal = (modalType) => {
    // First set the correct modal to display.
    setModal(modalType);
    // Finally toggle the modal
    toggleModal();
  };

  const openSignup = loggedIn
    ? () => NextRouter.push("/profile")
    : () => openModal("signup");

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item>
        {headerWithDesc && <HeroDescription />}
        {!headerWithDesc && <HeroTitle />}
      </Grid>
      <Grid item>
        <Grid
          container
          style={{
            marginTop: ".7rem",
          }}
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Button
              onClick={openSignup}
              variant="contained"
              color="primary"
            >
              Get Started
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => NextRouter.push("/blogroll")}
              variant="outlined"
              color="primary"
            >
              Learn More
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HeroReusableV1;
