import React from "react";
import useScreenSize from "../customHooks/useScreenSize";
import Image from "next/legacy/image";
import Box from "@mui/material/Box";

const StarChart = ({ margin }) => {
  const screenLayoutType = useScreenSize();

  const naturalAspectRatio = 683 / 425;
  const width = screenLayoutType === "smallScreen" ? 335 : 600;
  const height = width / naturalAspectRatio;

  return (
    <Box
      position="relative"
      width={width}
      height={height}
      margin={screenLayoutType === "smallScreen" && margin}
    >
      <Image src="/StarChart-compressed.png" alt="logo"  layout="fill" priority />
    </Box>
  );
};

export default StarChart;
