/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import StarChart from "../StarChart";
import Grid from "@mui/material/Grid";
import HeroReusableV1 from "./HeroReusableV1";
import useScreenSize from "components/customHooks/useScreenSize";

const HeroV3 = () => {
  const screenLayoutType = useScreenSize();
  // The Number extension for classNames based on the component
  const versionNumber = "110";
  return (
    <div className="layout-container">
      <Grid container justifyContent="center" height={screenLayoutType === "smallScreen" ? "auto" : 525}>
        <Grid item lg={5} md={12} sm={12} margin={screenLayoutType === "smallScreen" ? ".4rem auto auto" : "auto"}>
          <HeroReusableV1 headerWithDesc={true} versionNumber={versionNumber} />
        </Grid>
        <Grid item lg={7} md={12} sm={12} margin={screenLayoutType === "smallScreen" ? "3.1rem 0 1rem" : "auto"}>
          <Grid display="flex" justifyContent="center" className="findme1">
            <StarChart margin={"75px 0 66px"}/>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default HeroV3;
